import React, {useState, useEffect, useContext} from "react";
import '@scss/pages/home/index.scss';

import {debounce} from "throttle-debounce";
import Header from "@Components/Header.jsx";
import {Swiper, SwiperSlide} from "swiper/react";
import Marquee from "react-fast-marquee";
import {Scrollbar, Autoplay } from 'swiper/modules';

// Website translation

import {useLaravelReactI18n} from "laravel-react-i18n";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {MarkupContext} from "@/Contexts/index";
import {addLeadingZeros, getViewportWidth} from "@/Utils/index.js";
import Footer from "@/Components/Footer.jsx";
import ContactsForm from "@/Components/ContactsForm.jsx";
import ChatBot from "@/Components/ChatBot/ChatBot.jsx";
import {Head} from "@inertiajs/react";
import DecorBlob from "@/Components/Decor/DecorBlob.jsx";
import DecorContainer from "@/Components/Decor/DecorContainer.jsx";

function HeroBrands () {

    const imagesArr = [];
    for	(let i=1; i <= 21; i++ ) {
        imagesArr.push(require(`@images/pages/home/partner-logo-${i}.png`).default);
    }

    return (
        <>
            <Marquee className={'hero__brands masked-overflow'}>
                {imagesArr.map((path, index) => {
                    return <div key={index} className={'hero__brands-item'}><img src={path} alt="partner-logo"/></div>;
                })}
            </Marquee>
            {/*<marquee behavior={'alternate'} className={'hero__brands masked-overflow'}>*/}
            {/*    */}
            {/*</marquee>*/}
        </>
    );
}

function ServicesCards ({services}) {

    return (
        <div className={'services__cards'}>
            {services.map(data => (
                <div className={'services__card'} key={data.id}>
                    <div className={'services__card-preview'}>
                        <img src={`/storage/${data.image}`} alt="card-preview"/>
                    </div>
                    <div className={'services__card-content'}>
                        <img className={'services__card-icon'} src={`/storage/${data.icon}`} alt="card-icon"/>
                        <p><b>{addLeadingZeros(data.order, 2)}</b></p>
                        <p className={'services__card-title'} dangerouslySetInnerHTML={{__html: data.name}}></p>
                        <div className={'services__card-bullets'} dangerouslySetInnerHTML={{__html: data.description}}></div>
                    </div>
                </div>
            ))}
        </div>
    );
}

function CasesCards ({cases}) {
    const {t} = useLaravelReactI18n();
	return (
		<div className={'cases__cards'}>
			{cases.map(data => (
				<div className={'cases__card'} key={data.id}>
					<div className={'cases__card-preview'}>
						<img src={`/storage/${data.image}`} alt="card-preview"/>
					</div>
					<div className={'cases__card-content'}>
						<div className={'cases__card-tag'}>{data.category}</div>
						<div className={'cases__card-title'}>{data.name}</div>

						<div className={'cases__card-group'}>
							<p className={'cases__card-group-title'}>{t('site.casesBlock.groupObjective')}:</p>
							<p className={'cases__card-group-desc cases__card-group-desc--purple'} dangerouslySetInnerHTML={{__html: data.objective}}></p>
						</div>

						<div className={'cases__card-row'}>
							<div className={'cases__card-group'}>
								<p className={'cases__card-group-title'}>{t('site.casesBlock.groupSolution')}</p>
								<p className={'cases__card-group-desc'} dangerouslySetInnerHTML={{__html: data.solution}}></p>
							</div>

							<div className={'cases__card-group'}>
								<p className={'cases__card-group-title'}>{t('site.casesBlock.groupResult')}</p>
								<p className={'cases__card-group-desc'} dangerouslySetInnerHTML={{__html: data.result}}></p>
							</div>
						</div>
					</div>
				</div>
			))}

		</div>
	);
}

function FeedbackItem ({data}) {
    return (
        <div className={'review'}>
            <div className={'review__head'}>
                <div className={'review__head-avatar'}>
                    <img src={`/storage/${data.image}`} alt="reviewer-avatar"/>
                </div>
                <div className={'review__head-name'}>
                    {data.name}, <br/>
                    {data.company}
                </div>
            </div>
            <div className={'review__content'} dangerouslySetInnerHTML={{__html: data.body}}></div>
        </div>
    );
}

function FeedbackCards ({reviews}) {

	const [slidePerView, setSlidePerView] = useState('auto');

	const slideSize = 550;
	const checkSize = debounce(50, () => setSlidePerView(() =>
        getViewportWidth() <= slideSize
            ? 1
            : 'auto'
    ));

	useEffect(() => {
		window.addEventListener('resize', checkSize);
		window.addEventListener('orientationchange', checkSize);
		return () => {
			window.removeEventListener('resize', checkSize);
			window.removeEventListener('orientationchange', checkSize);
		};
	}, []);

	return (
		<div className={'feedback__cards'}>
			<Swiper
				className={'masked-overflow slider-main'}
				modules={[Scrollbar, Autoplay]}
				spaceBetween={16}
				slidesPerView={slidePerView}
				speed={1000}
				scrollbar={{ draggable: true }}
				autoplay={{
					delay: 3000,
					disableOnInteraction: true,
					pauseOnMouseEnter: false,
				}}
			>
				{reviews.filter(data => data.parent_id === null).map(data => (
                    <SwiperSlide key={data.id}>
                        <div className={'slide-content'}>
                            <div className={'feedback__card'}>
                                <FeedbackItem data={data} key={data.id}></FeedbackItem>
                                {reviews.filter(el => el.parent_id === data.id).map(dataInner => (
                                    <FeedbackItem data={dataInner} key={dataInner.id}></FeedbackItem>
                                ))}
                            </div>
                        </div>
                    </SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}

function CredenceCards ({credences}) {

	const [isShowMobile, setIsShowMobile] = useState(false);

	const {stateMobileMode} = useContext(MarkupContext);
	const {isMobileMode} = stateMobileMode;

    useEffect(() => {
        if ( !isShowMobile && isMobileMode ) {
            document.getElementById('credence')?.scrollIntoView();
        }
    }, [isShowMobile]);

	let render;
	if ( !isMobileMode ) {
		render = (
			<Swiper
				className={'masked-overflow slider-main'}
				modules={[Scrollbar, Autoplay]}
				spaceBetween={36}
				slidesPerView={'auto'}
				speed={1000}
				scrollbar={{ draggable: true }}
				autoplay={{
					delay: 3000,
					disableOnInteraction: true,
					pauseOnMouseEnter: false,
				}}
			>
				{credences.map((data, index) => (
					<SwiperSlide style={{maxWidth: data.width ?? `${data.width}px` }} key={data.id}>
						<div className={'slide-content'}>
							<div className={'credence__card'}>
								<div className={'credence__card-counter'}>
									<span><b>{addLeadingZeros(index+1, 2)}</b></span>
									/<span>{addLeadingZeros(credences.length, 2)}</span>
								</div>
								<div className={'credence__card-title'} dangerouslySetInnerHTML={{__html:data.name}}></div>
								<div className={'credence__card-row'}>
									<div className={'credence__card-preview'}><img src={`/storage/${data.image}`} alt="preview"/></div>
									<div className={'credence__card-content'} dangerouslySetInnerHTML={{__html:data.body}}></div>
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		)
	} else {
		render = (
			<>
				{credences.map((data, index) => (
                    <div className={'credence__card'} key={data.id}>
                        <div className={'credence__card-counter'}>
                            <span><b>{addLeadingZeros(index+1, 2)}</b></span>
                            /<span>{addLeadingZeros(credences.length, 2)}</span>
                        </div>
                        <div className={'credence__card-title'} dangerouslySetInnerHTML={{__html:data.name}}></div>
                        <div className={'credence__card-row'}>
                            <div className={'credence__card-preview'}><img src={`/storage/${data.image}`} alt="preview"/></div>
                            <div className={'credence__card-content'} dangerouslySetInnerHTML={{__html:data.body}}></div>
                        </div>
                    </div>
				))}
			</>
		);
	}

	return (
		<>
			<div className={`credence__cards ${ (isMobileMode && !isShowMobile) ? 'hide-items' : '' }`}>
				{render}
			</div>
			{ isMobileMode ? (
				<button className={'credence__btn'} onClick={()=>setIsShowMobile(!isShowMobile)}>
					{ isShowMobile ? 'Hide' : 'Show all' }
				</button>
			) : '' }
		</>

	);
}

function TeamCards ({team}) {

	return (
		<div className={'team__cards'}>
			{team.map(data => (
				<div className={'team__card'} key={data.id}>
					<div className={'team__card-preview'}><img src={`/storage/${data.image}`} alt="team-avatar"/></div>
					<div className={'team__job-title'} dangerouslySetInnerHTML={{__html: data.job_title}}></div>
					<div className={'team__name'} dangerouslySetInnerHTML={{__html: data.lastname}}></div>
				</div>
			))}
		</div>
	);
}

function Home(pageProps) {
    const {t} = useLaravelReactI18n();
	return (
		<>
            <ChatBot csrf_token={pageProps.csrf_token} session_id={pageProps.session_id} is_admin={pageProps.is_admin} ></ChatBot>

            <Head>
                <title>{t('site.meta.title')}</title>
                <meta name="title" content={t('site.meta.title')} />
                <meta name="description" content={t('site.meta.description')} />

                <meta name="og:title" content={t('site.meta.title')} />
                <meta name="og:description" content={t('site.meta.description')} />
            </Head>

			<Header locale={pageProps.locale} menu={pageProps.menu}></Header>

			<section className={'hero container'}>
				<div className={'hero__bg'}>
                    <video loop playsInline disablePictureInPicture autoPlay muted>
                        <source src="https://vivanti.eu/storage/videos/hero_bit.mp4" type="video/mp4" />
                    </video>
				</div>
				<div className={'container__inner'}>
					<div className={`hero__content`}>
						<h1 className={`hero__title ${pageProps.locale === 'fr' ? 'hero__title--fr mb-auto' : ''}`} dangerouslySetInnerHTML={{__html: pageProps.settings.find(el => el.key ==="header")?.value}}></h1>
						<div className={'hero__regals'}>
                            { pageProps.locale === 'en' &&
                                <div className={'hero__benefits'}>
                                    <div className={'hero__benefit-item'}>
                                        <span className={'hero__benefit-item-icon hero__benefit-item-icon--message'}></span>
                                        <span dangerouslySetInnerHTML={{__html: t('site.heroBlock.titlePart1')}}></span>
                                    </div>
                                    <div className={'hero__benefit-item hero__benefit-item--reverse'}>
                                        <span className={'hero__benefit-item-icon hero__benefit-item-icon--science'}></span>
                                        <span dangerouslySetInnerHTML={{__html: t('site.heroBlock.titlePart2')}}></span>
                                    </div>
                                    <div className={'hero__benefit-item'}>
                                        <span dangerouslySetInnerHTML={{__html: t('site.heroBlock.titlePart3')}}></span>
                                    </div>
                                    <div className={'hero__benefit-item hero__benefit-item--reverse'}>
                                        <span className={'hero__benefit-item-icon hero__benefit-item-icon--success'}>Success</span>
                                        <span dangerouslySetInnerHTML={{__html: t('site.heroBlock.titlePart4')}}></span>
                                    </div>
                                </div>
                            }
                            { pageProps.locale === 'fr' &&
                                <div className={'hero__benefits hero__benefits--fr'}>
                                    <div className={'hero__benefit-item'}>
                                        <span className={'hero__benefit-item-icon hero__benefit-item-icon--message'}></span>
                                        <span dangerouslySetInnerHTML={{__html: t('site.heroBlock.titlePart1') }}></span>
                                    </div>
                                    <div className={'hero__benefit-item'}>
                                        <span dangerouslySetInnerHTML={{__html: t('site.heroBlock.titlePart2') }}></span>
                                    </div>
                                    <div className={'hero__benefit-item hero__benefit-item--reverse'}>
                                        <span className={'hero__benefit-item-icon hero__benefit-item-icon--science'}></span>
                                        <span dangerouslySetInnerHTML={{__html: t('site.heroBlock.titlePart3') }}></span>
                                    </div>
                                    <div className={'hero__benefit-item'}>
                                        <span dangerouslySetInnerHTML={{__html: t('site.heroBlock.titlePart4') }}></span>
                                    </div>
                                </div>
                            }
							<a href={'#contact'} className={'hero__btn-contact'}><span>{t('site.heroBlock.textContactBtn')}</span></a>
						</div>
					</div>

					<p className={'hero__description my-24px ph:mt-30px ph:mb-10px'}>{t('site.heroBlock.heroSubtitle')}</p>
					<HeroBrands></HeroBrands>

				</div>
			</section>

			<section className={'full-cycle container mb-80px ph:mb-100px'}>
				<div className={'container__inner'}>
					<div className={'full-cycle__indicators'}>
						<div className={'full-cycle__indicator-item'}>
							<div className={'full-cycle__indicator-item-value'}>12</div>
							<div className={'full-cycle__indicator-item-text'} dangerouslySetInnerHTML={{__html: t('site.fullCycleBlock.cycleItemText1') }}></div>
						</div>
						<div className={'full-cycle__indicator-item'}>
							<div className={'full-cycle__indicator-item-value'}>100+</div>
							<div className={'full-cycle__indicator-item-text'}>{t('site.fullCycleBlock.cycleItemText2')}</div>
						</div>
						<div className={'full-cycle__indicator-item'}>
							<div className={'full-cycle__indicator-item-value'}>5000+</div>
							<div className={'full-cycle__indicator-item-text'}>{t('site.fullCycleBlock.cycleItemText3')}</div>
						</div>
						<div className={'full-cycle__indicator-item'}>
							<div className={'full-cycle__indicator-item-value'}>200+</div>
							<div className={'full-cycle__indicator-item-text'}>{t('site.fullCycleBlock.cycleItemText4')}</div>
						</div>
					</div>
					<div className={'full-cycle__content'} dangerouslySetInnerHTML={{__html: pageProps.settings.find(el => el.key ==="full_cycle")?.value}}></div>
				</div>
			</section>

			<section className={'services container mb-80px ph:mb-100px'} id="services">
				<div className={'container__inner'}>
					<h2 className={'text-right mb-48px ph:mb-60px ph:text-left'}>{t('site.servicesBlock.subTitle')}</h2>
					<p className={'mb-48px ph:mb-60px'} dangerouslySetInnerHTML={{__html: pageProps.settings.find(el => el.key ==="services")?.value}}></p>
					<ServicesCards services={pageProps.services}></ServicesCards>
				</div>
                <DecorContainer>
                    <DecorBlob
                        typeNum={4}
                        width={1320}
                        blur={100}
                        rotate={50.46}
                        opacity={0.7}
                        x={-260}
                        y={-130}
                    />
                    <DecorBlob
                        typeNum={4}
                        width={1868}
                        blur={117}
                        rotate={-49.21}
                        opacity={0.7}
                        x={1170}
                        y={-8}
                    />
                </DecorContainer>
			</section>

			<section className={'cases container mb-80px ph:mb-100px'} id="cases">
				<div className={'container__inner'}>
					<h2 className={'mb-48px ph:mb-60px'}>{t('site.casesBlock.subTitle')}</h2>
					<CasesCards cases={pageProps.cases}></CasesCards>
				</div>
                <DecorContainer>
                    <DecorBlob
                        typeNum={4}
                        width={2500}
                        blur={225}
                        rotate={50.46}
                        opacity={0.7}
                        x={-490}
                        y={-300}
                    />
                </DecorContainer>
			</section>

			<section className={'feedback container mb-80px ph:mb-100px'}>
				<div className={'container__inner'}>
					<h2 className={'mb-48px ph:mb-60px'}>{t('site.feedbackBlock.subTitle')}</h2>
					<FeedbackCards reviews={pageProps.reviews}></FeedbackCards>
				</div>
                <DecorContainer>
                    <DecorBlob
                        typeNum={4}
                        width={1708}
                        blur={225}
                        rotate={-165.04}
                        opacity={0.6}
                        x={-460}
                        y={-400}
                    />
                </DecorContainer>
			</section>

			<section className={'why-vivanti container mb-80px ph:mb-100px'} id="about">
				<div className={'container__inner'}>
					<h2 className={'mb-48px ph:mb-60px'}>{t('site.whyVivantiBlock.subTitle')}</h2>
					<div className={'why-vivanti__row'}>
						<div className={'why-vivanti__list'}>
							<ul className={'why-vivanti__bullets'} dangerouslySetInnerHTML={{__html: pageProps.settings.find(el => el.key ==="why_vivanti")?.value}}></ul>
						</div>
						<div className={'why-vivanti__content'}>
							<p className={'why-vivanti__content-title text-[#BCD9FF] text-[36rem] ph:text-[20rem]'}>{t('site.whyVivantiBlock.contentTitle')}</p>
							<div className={'why-vivanti__counter why-vivanti__counter--1'} dangerouslySetInnerHTML={{__html: t('site.whyVivantiBlock.counterText1')}}></div>
							<div className={'why-vivanti__counter why-vivanti__counter--2'}><mark>&gt;5000</mark> {t('site.whyVivantiBlock.counterText2')}</div>
							<div className={'why-vivanti__counter why-vivanti__counter--3'}><mark>&gt;100,000</mark><br/>{t('site.whyVivantiBlock.counterText3')}</div>
						</div>
					</div>
				</div>
                <DecorContainer>
                    <DecorBlob
                        typeNum={2}
                        width={735.96}
                        blur={0}
                        rotate={-176.1}
                        opacity={1}
                        x={290}
                        y={660}
                        animations={['pulse']}
                        animDuration={8}
                        gsapMove={true}
                    />
                    <DecorBlob
                        typeNum={1}
                        width={1314}
                        blur={0}
                        rotate={13.99}
                        opacity={1}
                        x={-270}
                        y={-100}
                        animations={['pulse']}
                        animDuration={12}
                        gsapMove={true}
                    />
                </DecorContainer>
			</section>

			<section className={'credence container mb-80px ph:mb-100px'} id={'credence'}>
				<div className={'container__inner'}>
					<h2 className={'mb-48px ph:mb-60px'}>{t('site.credenceBlock.subTitle')}</h2>
					<CredenceCards credences={pageProps.credences}></CredenceCards>
				</div>
                <DecorContainer>
                    <DecorBlob
                        typeNum={2}
                        width={2920}
                        blur={300}
                        rotate={-147.49}
                        opacity={1}
                        x={-520}
                        y={-570}
                    />
                    <DecorBlob
                        typeNum={4}
                        width={152}
                        blur={0}
                        rotate={0}
                        opacity={1}
                        x={205}
                        y={577}
                        animations={['pulse']}
                        animDuration={6}
                        gsapMove={true}
                    />
                    <DecorBlob
                        typeNum={4}
                        width={279.23}
                        blur={20}
                        rotate={-165.49}
                        opacity={1}
                        x={1585}
                        y={-47}
                        animations={['pulse']}
                        animDuration={8}
                        gsapMove={true}
                    />
                </DecorContainer>
			</section>

			<section className={'team container mb-80px ph:mb-100px'}>
				<div className={'container__inner'}>

					<div className={'team__desc'}>
						<h2 className={'mb-48px ph:mb-60px'}>{t('site.teamBlock.subTitle')}</h2>
						<div className={'team__desc-list'} dangerouslySetInnerHTML={{__html: pageProps.settings.find(el => el.key ==="team")?.value}}></div>
					</div>

					<TeamCards team={pageProps.team}></TeamCards>
				</div>
                <DecorContainer>
                    <DecorBlob
                        typeNum={1}
                        width={2053.68}
                        blur={0}
                        rotate={13.99}
                        opacity={1}
                        x={806}
                        y={272}
                        animations={['pulse']}
                        animDuration={12}
                        gsapMove={true}
                    />
                    <DecorBlob
                        typeNum={4}
                        width={2611.08}
                        blur={290}
                        rotate={81.61}
                        opacity={1}
                        x={-520}
                        y={-50}
                    />
                </DecorContainer>
			</section>

			<section className={'careers container mb-80px ph:mb-100px'}>
				<div className={'container__inner'}>

					<div className={'careers__row'}>
						<div className={'careers__content'}>
							<h2 className={'mb-48px ph:mb-60px'}>{t('site.careersBlock.subTitle')}</h2>
                            <div dangerouslySetInnerHTML={{__html: pageProps.settings.find(el => el.key ==="careers")?.value}}></div>
						</div>

						<div className={'careers__form'}>
							<p className={'careers__form-title'} dangerouslySetInnerHTML={{__html: t('site.careersBlock.CVTitle')}}></p>
							<a href='mailto:info@vivanti.eu' className={'careers__form-btn'}>{t('site.careersBlock.CVTextButton')}</a>
							<p className={'careers__form-subtitle text-center'}>{t('site.careersBlock.CVText')}</p>
						</div>
					</div>
				</div>
			</section>

			<section className={'contact container mb-80px ph:mb-[160px]'} id="contacts">
				<div className={'container__inner'}>
					<div className={'contact__row'}>
						<div className={'contact__text'}>
							<h2 className={'mb-48px ph:mb-20px'} id={'contact'} dangerouslySetInnerHTML={{__html: t('site.contactBlock.subTitle')}}></h2>
							<p className={'contact__subtitle text-[24rem] max-w-[380px] font-medium'} dangerouslySetInnerHTML={{__html: pageProps.settings.find(el => el.key ==="contacts")?.value}}></p>
						</div>
						<ContactsForm locale={pageProps.locale} />
					</div>
				</div>
			</section>

            <Footer></Footer>
		</>
	);
}

export default Home;
