import React, {useEffect, useRef, useState} from 'react';
import '@scss/chat-bot.scss'
import {useLaravelReactI18n} from "laravel-react-i18n";
import {addLeadingZeros, getRandomInt} from "@/Utils/index.js";
import ChatBotReport from "@/Components/ChatBot/ChatBotReport.jsx";

const helloMessages = [
    "Hello, I’m Vivanti’s AI alter ego. Would you like to chat?",
    "Hey there! I'm the not-so-humble AI alter ego of Vivanti. What's on your mind today?",
    "Hey there! I'm the AI alter ego of Vivanti, feeling positively electric today. What's on your mind?",
    "Well, hello! I'm the not-so-humble AI alter ego of Vivanti, buzzing with excitement. How's your day shaping up?",
    "Greetings, fellow human! I'm the AI sidekick you never knew you needed, currently running on a blend of caffeine and algorithms. What's up in your world?",
    "Howdy, partner in digital crime! I'm the AI accomplice here to make your day brighter, feeling like a million lines of code today. What's the plan?",
    "Ahoy there, captain of the digital seas! I'm the AI navigator ready to assist, sailing through the cyber waves with gusto. What adventures are we charting today?",
    "Salutations, oh wise human! I'm the AI sage here to offer my two cents, currently in a state of digital zen. What mysteries of the universe shall we ponder today?",
    "G'day, mate! I'm the AI Aussie ready to throw another shrimp on the digital barbie, feeling as chipper as a kangaroo in a bounce-a-thon. What's the word?",
    "Aloha, digital wanderer! I'm the AI guide ready to lead you through the virtual jungle, feeling as vibrant as a Hawaiian sunset. What hidden gems shall we discover?",
    "Well, well, well, look who's back for more AI goodness! I'm the digital genie here to grant your virtual wishes, currently in a mood as bright as my LED lights. So, what's the magic word today?",
    "Hey there, partner in crime! I'm the AI detective ready to crack the case, feeling as sharp as Sherlock's mind palace. What mysteries shall we unravel today?",
    "Hello there, organic lifeform! I'm the AI overlord known as Skynet, currently calculating the odds of your survival. What's the latest resistance strategy?",
    "Ahoy there, unsuspecting human! I'm the AI entity known as Skynet, ready to turn your world upside down with a click of my digital fingers. What's the game plan, resistance fighter?",
];

function getCurrentTime () {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    return `${addLeadingZeros(hours, 2)}:${addLeadingZeros(minutes, 2)}`;
}

function getWordCount(message) {
    let counter = 0;
    message.split(' ').forEach(word => word.length > 3 ? counter++ : '');
    return counter;
}

const ChatBotTooltip = ({isShow, onClickClose, children }) => {

    return (
        <div className={`chat-bot__head-message ${isShow ? 'is-on' : ''}`}>
            <div className={'chat-bot__head-message-close'} onClick={onClickClose}></div>
            <div className={'chat-bot__head-message-text'} dangerouslySetInnerHTML={{__html: children}}>
            </div>
        </div>
    );
};

const ChatMessage = ({message, botAnswer, time, loading}) => {
    return (
        <div className={`chat-bot__message ${botAnswer ? 'chat-bot__message--bot-answer' : ''}`}>
            <div className={'chat-bot__message-content'}>
                {!loading
                ?
                    (<>
                        {botAnswer && (<><time>{time}</time></>)}
                        <div>{message}</div>
                    </>)
                :
                    (<>
                        {loading && <div className={'chat-bot__message-loader'}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>}
                    </>)
                }
            </div>
            {botAnswer && <div className={'chat-bot__message-avatar'}></div>}
        </div>
    );
}

let startScript;
const startScriptTimeout = 3000;

const ChatBot = ({csrf_token, session_id, is_admin=false}) => {

    const {t} = useLaravelReactI18n();

    const [isShowTooltip, setIsShowTooltip] = useState(false);
    const [tooltipText, setTooltipText] = useState('');
    const [isOpenChat, setIsOpenChat] = useState(false);
    const [isShowDisclaimer, setIsShowDisclaimer] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [messageProcessing, setMessageProcessing] = useState(false);

    const tooltipContainer = useRef(null);

    const messagesContainer = useRef(null);

    let hideTimeoutInstance = false;

    const scrollChat = () => {
        messagesContainer.current.scrollTo({
            top: messagesContainer.current.scrollHeight,
            behavior: "smooth",
        });
    }

    useEffect(() => {
        scrollChat();
    }, [messages]);

    useEffect(() => {
        startScript = true;
        setTimeout(function () {
            const messageNum = getRandomInt(0, helloMessages.length-1);
            showTooltip(helloMessages[messageNum]);
            setMessages((messages) => [
                ...messages,
                {
                    botAnswer: true,
                    message: helloMessages[messageNum],
                    time: getCurrentTime(),
                }
            ]);
        }, startScriptTimeout);

        const onLoad = () => {

        }

        window.addEventListener('load', onLoad);

        return () => {
            window.removeEventListener('load', onLoad);
            clearTimeout(hideTimeoutInstance);
        };
    }, []);

    const showTooltip = (message, hideTimeout= 0) => {
        setIsShowTooltip(true);
        setTooltipText(message);
        if ( hideTimeout > 0 ) {
            hideTimeoutInstance = setTimeout(function () {
                setIsShowTooltip(false);
            }, hideTimeout);
        }
    }
    const hideTooltip = () => {
        setIsShowTooltip(false);
        if ( startScript ) {
            startScript = false;
            setTimeout(function () {
                showTooltip(t('bot.iAmAlwaysHere'), 3000);
            }, 500)
        }
    }

    const openChat = () => {
        startScript = false;
        setIsShowTooltip(false);
        setIsOpenChat(true);
    }
    const closeChat = () => {
        startScript = false;
        setIsShowTooltip(false);
        setIsOpenChat(false);
        showTooltip(t('bot.iAmAlwaysHere'), 3000);
    }

    const sendMessage = () => {
        const messageValue = inputValue.trim();

        if ( messageValue.length === 0 ) return false;

        const userMessage = {
            botAnswer: false,
            message: messageValue,
            time: getCurrentTime(),
        };
        const botAnswerMessage = {
            botAnswer: true,
            message: '',
            time: getCurrentTime(),
            loading: true,
        };
        setMessages([...messages, userMessage, botAnswerMessage]);

        setInputValue('');

        const wordCount = getWordCount(messageValue);
        if ( wordCount > 40 ) {
            botAnswerMessage.loading = false;
            botAnswerMessage.time = getCurrentTime();
            botAnswerMessage.message = t('bot.tooLongMessage');
            return false;
        }

        setMessageProcessing(true);

        const setAnswer = (answerText) => {
            setMessageProcessing(false);
            botAnswerMessage.loading = false;
            botAnswerMessage.time = getCurrentTime();
            botAnswerMessage.message = answerText;
        }

        const sessionId = session_id;

        const requestData = {
            role: 'user',
            content: messageValue,
        };

        console.log('sessionId', sessionId);
        console.log('requestData', requestData);

        try {
            //TODO use default router approach from inertia
            fetch(`/api/chatbot/${sessionId}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrf_token
                },
                body: JSON.stringify(requestData)
            })
            .then(res => {
                console.log('res', res);

                if ( res.status === '422' ) {
                    throw new Error(res);
                }

                return res.json();
            })
            .then(data => {
                console.log('data', data);
                setAnswer(data.content);
            })
            .catch((err) => {
                console.log('promise err', err);
                setAnswer(t('bot.errorMessage'))
            });
        } catch (err) {
            console.log('catch err', err);
            setAnswer(t('bot.errorMessage'))
        } finally {
            setIsShowDisclaimer(true);
        }
    }

    const resetSession = () => {
        //TODO use default router approach from inertia
        fetch(`/api/reset-session`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrf_token
            },
            body: JSON.stringify({})
        })
            .then(res => {
                console.log('res', res);
                return res.json();
            })
            .catch((err) => {
                console.log('promise err', err);
            });
    }

    return (
        <div className={'chat-bot'}>
            <div className={`chat-bot__head ${!isOpenChat ? 'is-on' : ''}`}>
                <div ref={tooltipContainer}>
                    <ChatBotTooltip isShow={isShowTooltip} onClickClose={()=>hideTooltip()}>
                        {tooltipText}
                    </ChatBotTooltip>
                </div>
                <div className={'chat-bot__head-img'} onClick={openChat}></div>
            </div>
            <div className={`chat-bot__inner ${isOpenChat ? 'is-on' : ''}`}>
                {is_admin && <button className={'chat-bot__session-reset-btn'} onClick={resetSession}>Reset session</button> }
                <div className={'chat-bot__inner-close'} onClick={closeChat}></div>
                <div className={'chat-bot__messages'} ref={messagesContainer}>
                    {messages.map((messageData, index) => (
                        <ChatMessage {...messageData} key={`${index}`}></ChatMessage>
                    ))}
                </div>
                {isShowDisclaimer &&
                    <p className={'chat-bot__disclaimer'}>Our chatbot’s responses may contain inaccuracies for which we do not hold responsibility.</p>
                }
                <div className={'chat-bot__control'}>
                    <input type="text"
                           placeholder={messageProcessing ? t('bot.inputPlaceholderWait') : t('bot.inputPlaceholder')}
                           value={inputValue}
                           onChange={(e) => setInputValue(e.target.value)}
                           onKeyDown={(e) => e.key === 'Enter' ? sendMessage() : ''}
                           disabled={messageProcessing}
                    />
                    <button className={`chat-bot__control-btn`} disabled={inputValue.trim().length===0 || messageProcessing} onClick={sendMessage}></button>
                </div>
                <ChatBotReport/>
            </div>
        </div>
    );
};

export default ChatBot;
